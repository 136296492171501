import * as $$ from "jquery-selector-cache";
import {breakpointsConfig} from "../../_core/scripts/specific/config/responsive";
import "slick-carousel";
import {getSliderIcon} from "../../elements/icon/icon";
/*import {isDesktopBreakpoint, isMobileOrTabletBreakpoint} from "../../_core/scripts/specific/layout/breakpoints";*/

/**
 * SLICK CAROUSEL
 */
export function initSliderModule() {
    if (!$$(".slider-module").length) {
        return;
    }

    // When item is lte 6
    if ($$(".slider-module").find(".thematic-item").length <= 6) {
        $$(".slider-module").addClass("column-mode");
        return;
    }

    // Configuration SLICK MODULE
    $(".slider-module").slick({
        rows: 0,
        slidesToShow: 6,
        slidesToScroll: 2,
        infinite: true,
        arrows: true,
        dots: true,
        prevArrow: getSliderIcon("slick-button-prev", "", ">"),
        nextArrow: getSliderIcon("slick-button-next", "", ">" ),
        responsive: [
            {
                breakpoint: 992,
                settings: "unslick"
            }
        ]
    });
}
