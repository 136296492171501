import * as $$ from "jquery-selector-cache";

// TOGGLE TEXT
export function initFoldingText() {
    if (!$$(".mod-folding-text").length) {
        return;
    }
    $$(".mod-folding-text").on("click", ".folding-text-toggle", toggleTextArea);
}

function toggleTextArea(evt: JQuery.Event) {
    const target = $(evt.currentTarget);
    const isOpen = target.hasClass("open");

    target.toggleClass("open", !isOpen);
    const foldingBox = target.parents(".folding-text-box");
    foldingBox.find(".folding-text-truncated").toggleClass("hidden", isOpen);
    const toggleText = foldingBox.find(".folding-text-link");
    if (isOpen && toggleText.length) {
        toggleText.text(toggleText.data("openLabel"));
    } else {
        toggleText.text(toggleText.data("closeLabel"));
    }
}
