import * as $$ from "jquery-selector-cache";
import {
    callSearchEngineHelper,
    SearchFieldResponse,
    updateLinkedSelect
} from "../../_core/scripts/libs/orxapi/SearchEngineHelper";
import {dataLayerPush, DataLayerPushAction} from "../../_core/scripts/specific/gtm";

export function initInspiration() {
    if (!$$(".for-inspiration").length) {
        return;
    }

    $$(".for-inspiration").on("change", ".choice-inspiration select", handleChangeInspirationSelect);
    $$(".for-inspiration").each((i, element) => searchInspiration("", $(element).find(".inspiration-result")));

    // GTM Data Layer
    const isHome = typeof $("body").data("home") !== "undefined";
    $$(".for-inspiration").on("change", ".choice-inspiration select", function(evt) { dataLayerPush(evt, isHome, DataLayerPushAction.Inspiration); });
}

function handleChangeInspirationSelect(evt: JQuery.Event) {
    const module = $(evt.delegateTarget);
    const updatedSelect = $(evt.currentTarget);
    const allSelect = module.find(".choice-inspiration select");
    const seasonSelectValue = module.find(".season-select").val();
    const notUpdatedSelect = allSelect.filter((i, select) => $(select).attr("name") !== updatedSelect.attr("name"));

    const dateBoundaryInput = module.find(".season-ipt");
    dateBoundaryInput.attr("disabled", "disabled");
    if (seasonSelectValue) {
        module.find(".season_" + seasonSelectValue).removeAttr("disabled");
    }
    const searchQuery = allSelect.serialize() + "&" + dateBoundaryInput.serialize();
    updateInspirationSelect(updatedSelect.serialize(), notUpdatedSelect);

    updateAllResultLink(module.find(".see-more a"), searchQuery);
    const resultContainer = module.find(".choice-inspiration").siblings(".inspiration-result");
    searchInspiration(searchQuery, resultContainer);
}

function updateAllResultLink(button, query) {
    button.attr("href", "/serp?" + query);
}

function updateInspirationSelect(query, selectToUpdate) {
    callSearchEngineHelper(query,
        selectToUpdate.attr("name").replace("s_c.", "cc."),
        (data: SearchFieldResponse) => updateLinkedSelect(selectToUpdate, data));
}

export function searchInspiration(searchQuery: string, resultContainer) {
    $(".inspiration-result").addClass("load");
    $.ajax("/inspiration/get?" + searchQuery +
        "&maxProduct=" + resultContainer.data("maxProduct") +
        "&maxArticle=" + resultContainer.data("maxArticle"))
        .done(data => {resultContainer.empty().append(data);$(".inspiration-result").removeClass("load");});
}