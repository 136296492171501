export interface SearchFieldResponse {
    [key: string]: [
        {
            code: string;
            label: string;
            entityNumber: number;
        }
        ];

}

export function callSearchEngineHelper(query: string, searchField: string, callBack) {
    $.ajax("/search/fields?" + query + "&s_sef=" + searchField, {
        dataType: "json"
    }).done(callBack);
}

export function updateLinkedSelect(selectToUpdate, data: SearchFieldResponse) {
    selectToUpdate.each((i, element) => {
        const select = $(element);
        select.find("option").filter((j, option) => $(option).attr("value") !== "").attr("disabled", "disabled");
        const existingValue = data[select.attr("name")];
        if(existingValue) {
            existingValue.forEach(value => select.find("option[value='" + value.code + "']").removeAttr("disabled"));
        }
        select.trigger("chosen:updated");
    });
}