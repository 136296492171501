import * as $$ from "jquery-selector-cache";

import "slick-carousel";

/**
 * SLICK CAROUSEL 5 RAISONS
 */
export function initSliderReassurance() {
    const sliderReassurance = $$(".slider-reassurance.mobile");

    // Exit when slider don't exist
    if (!sliderReassurance.length) {
        return;
    }

    const sliderSettings = {
        arrows: true,
        slidesToShow: 3,
        lazyLoad: "ondemand",
        centerMode: true,
        centerPadding: "45px",
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    sliderReassurance.slick(sliderSettings);
}
