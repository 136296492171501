import * as $$ from "jquery-selector-cache";

export function initShare() {
    if (!$$(".share").length) {
        return;
    }

    updateShareLinks();
}

function updateShareLinks() {
    $$(".share").each(function() {
        $$(this).find(".list-inline a").each(function() {
            const link = $$(this);
            const linkUrl = link.attr("href");
            if(!linkUrl.length) {
                if(link.hasClass("facebook")) {
                    link.attr("href", "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(location.href));
                }
                if(link.hasClass("twitter")) {
                    link.attr("href", "https://twitter.com/home?status=" + encodeURIComponent(location.href));
                }
                if(link.hasClass("linkedin")) {
                    link.attr("href", "https://www.linkedin.com/shareArticle?mini=true"
                        + "&url=" + encodeURIComponent(location.href)
                        + "&title=" + encodeURIComponent(document.title) + "&summary=&source=");
                }
            }
        });
    });
}