import * as $$ from "jquery-selector-cache";

import "../../_core/scripts/specific/layout/index";
import {initHeader, initMmenu} from "../../components/header/header";

import {initSliderFull} from "../../components/sliderFull/sliderFull";
import {initSliderReassurance} from "../../components/reassurance/reassurance";
import {initCustomSelect} from "../../components/customSelect/customSelect";
import {initDatepicker, initSearchEngine, loadTabSearch} from "../../components/search/search";
import {closeNotification} from "../../components/notification/notification";
import {initBackToTop} from "../../components/backtop/backtop";
import {initFooter} from "../../components/footer/footer";
import {initSliderMag} from "../../modules/pushContainer/pushContainer";
import {initInspiration} from "../../modules/inspiration/inspiration";
import {initFoldingText} from "../../modules/foldingText/foldingText";
import {initShare} from "../../components/share/share";
import {initLandingNav} from "../../components/landingNav/landingNav";
import {initSmoothScroll} from "../../_core/scripts/specific/layout/scroll";
import {initSliderModule} from "../../components/sliderModule/sliderModule";

if (console !== undefined) {
    console.log("initialize landing ;-)");
}

// Initialize menu
initMmenu();
initHeader();

// Initialize Sliders
initSliderFull();
initCustomSelect();
initSliderReassurance();
initSliderMag();
initSliderModule();
initFooter();
initSearchEngine();
initDatepicker();
initBackToTop();
initInspiration();
initFoldingText();
initShare();
initLandingNav();
loadTabSearch();

// Initialize Dark Blue Menu ( personal space nav )
/*$$(".landing-nav").on("click", "a", initSmoothScroll);*/

$$(".notification").on("click", ".close", closeNotification);