import * as $$ from "jquery-selector-cache";

import "slick-carousel";
/*import {isDesktopBreakpoint, isMobileOrTabletBreakpoint} from "../../_core/scripts/specific/layout/breakpoints";*/
/*import {breakpointsConfig} from "../../_core/scripts/specific/config/responsive";*/

/**
 * SLICK CAROUSEL
 */
export function initSliderFull() {
    if (!$$(".slider-full").length || !$$(".slider-full").length) {
        return;
    }

    const nbItems = ($$(".slider-full .slider-item").length > 4) ? 4 : $$(".slider-full .slider-item").length;

    // Configuration SLICK full
    $$(".slider-full").slick({
        dots: true,
        lazyLoad: "ondemand",
        centerMode: true,
        slidesToShow: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        asNavFor: ".slider-steps",
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    centerPadding: "80px"
                }
            },
            {
                breakpoint: 480,
                settings: {
                    centerPadding: "32px",
                    dots: true
                }
            }
        ]
    });
    $$(".slider-steps").slick({
        slidesToShow: nbItems,
        arrows: false,
        focusOnSelect: true,
        asNavFor: ".slider-full"
    });
}