import * as $$ from "jquery-selector-cache";

import "../../_core/scripts/libs/vendor/chosen.jquery";
/*import {customSelectConfig} from "../../_core/scripts/specific/config/form";*/
import {isMobileDevice} from "../../_core/scripts/specific/layout/device";

/**
 * Initialize the chosen select box
 */
export function initCustomSelect() {
    // Initialize the single select
    const singleSelect = $$("select.cpt-select-custom.cpt-single");
    const selectWithSearch = $$("select.cpt-select-custom.cpt-with-search");
    if (isMobileDevice()) {
        singleSelect.find(".empty-option").remove();
        selectWithSearch.find(".empty-option").remove();
    }

    if (singleSelect.length) {
        singleSelect.chosen($.extend({}, {
            disable_search_threshold: 999,
            enable_escape_special_char: true,
            disable_search: true,
            placeholder_text_single: singleSelect.data("placeholder") || ""
        }));
    }

    if (selectWithSearch.length) {
        selectWithSearch.chosen({
            disable_search_threshold: 0,
            enable_escape_special_char: true,
            search_length_min: 2,
            disable_search: false,
            placeholder_text_single: selectWithSearch.data("placeholder") || ""
        });
    }

}

/**
 * Add a first option on select with mobile device
 */
export function addPlaceholderSelect() {
    $$(".add-placeholder-select select.cpt-select-custom").each((idx: number, elem: HTMLElement) => {
        const select = $(elem);
        const placeholder = select.data("placeholder") || "";
        select.find("option").first().text(placeholder);
    });
}
