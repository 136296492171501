import * as $$ from "jquery-selector-cache";
import {
    isMobileBreakpoint,
    isTabletBreakpoint
} from "../../_core/scripts/specific/layout/breakpoints";

/**
 * SLICK CAROUSEL 5 RAISONS
 */
export function initSliderMag() {

    const sliderMag = $$(".slider-mag");

    // Exit when slider don't exist
    if (!sliderMag.length) {
        return;
    }
    if (isMobileBreakpoint() || isTabletBreakpoint()) {
        sliderMag.slick({
            lazyLoad: "ondemand",
            centerMode: true,
            dots: true,
            arrows: false,
            centerPadding: "0px",
            slidesToShow: 1
        });
    }

}