import * as $$ from "jquery-selector-cache";

export function initLandingNav() {
    $$(".landing-nav").on("click", ".button-menu", openLandingNavMobile);
    $$(".landing-nav").on("click", "ul li", openLandingNavChooseMobile);
}

function openLandingNavMobile(evt: JQuery.Event) {
    const target = $$(evt.currentTarget);
    target.parents(".landing-nav").find("ul").toggleClass("is-open");

}

function openLandingNavChooseMobile(evt: JQuery.Event) {
    const target = $$(evt.currentTarget);
    target.parents("ul").toggleClass("is-open");
    target.parents("ul").find("li").removeClass("is-active");
    target.addClass("is-active");

    const href = target.parents("ul").find("li.is-active a").attr("href");
    const name = href.replace("#", "");

    if ( href === "#") { return; }

    var offset = 0;

    if ( $$("body").hasClass("bp-xs") || $$("body").hasClass("bp-md")  ) {
        var offset = 35;
    }
    if ( $$(href) === true  ) {
        // if id
        $$("html, body").animate({ scrollTop: $$(href).offset().top - offset - 47 }, 500);
    } else {
        // if a attr name
        $$("html, body").animate({ scrollTop: $$(".mod-block-html a[name='" + name + "']").offset().top - offset - 47 }, 500);
    }
}