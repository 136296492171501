import * as $$ from "jquery-selector-cache";
import * as flatpickr from "flatpickr";
import {French} from "flatpickr/dist/l10n/fr.js";
import {
    callSearchEngineHelper,
    SearchFieldResponse,
    updateLinkedSelect
} from "../../_core/scripts/libs/orxapi/SearchEngineHelper";
import {dataLayerPush, DataLayerPushAction} from "../../_core/scripts/specific/gtm";

export function loadTabSearch() {
    function removeClassLoad() {
        $("article.search").removeClass("load");
    }

    $$(window).on("load", removeClassLoad());
}

export function initSearchEngine() {
    if (!$$("#searchEngineForm").length) {
        return;
    }

    updateSelectFromDestinationValue($$("#searchEngineForm"), $$("#searchEngineForm").find(".destination-select"));
    initDatepickerValue(); // Used for browser back button

    $$("#searchEngineForm").on("change", ".datepicker-home", handleChangeDate);
    $$("#searchEngineForm").on("change", ".destination-select", handleChangeDestination);
    const isHome = typeof $("body").data("home") !== "undefined";
    $$("#searchEngineForm").on("click", "input[type=submit]", function(evt) { dataLayerPush(evt, isHome, DataLayerPushAction.SearchEngine); });
}

function initDatepickerValue() {
    const dateInput = $$("input.datepicker-home");
    const beforeBackDateVal = String(dateInput.val());
    const afterBackDateVal = sessionStorage.getItem("searchEngineDate");
    // Stores date if there is a value, before leaving the page
    window.addEventListener("beforeunload", () => {
        if (beforeBackDateVal && beforeBackDateVal.length) {
            sessionStorage.setItem("searchEngineDate", beforeBackDateVal);
        } else {
            sessionStorage.setItem("searchEngineDate", "");
        }
    });
    // Updates date if there is a value stored before unloading the previous page and if there is not already a value in the field (from request)
    if ((!beforeBackDateVal || !beforeBackDateVal.length) && afterBackDateVal && afterBackDateVal.length) {
        updateDates(dateInput, afterBackDateVal);
    }
}

function handleChangeDate(evt: JQuery.Event<HTMLInputElement>) {
    const dateInput = $(evt.currentTarget);
    const dateVal = String(dateInput.val());
    updateDates(dateInput, dateVal);
}

function updateDates(dateInput, dateValue) {
    const selectedDate = dateValue.split("-");
    dateInput.val(dateValue);
    dateInput.parents(".cpt-date-picker").siblings("input[name='s_dd']").val(selectedDate[2]);
    dateInput.parents(".cpt-date-picker").siblings("input[name='s_dmy']").val(selectedDate[1] + "/" + selectedDate[0]);
}

function updateSelectFromDestinationValue(form, selectDestination) {
    const searchSelect = form.find(".default-select");
    const searchField: string = searchSelect
        .filter((i, element: HTMLElement) => $(element).attr("name") !== "s_aj")
        .map((i, element: HTMLElement) => $(element).attr("name"))
        .map((i, name: string) => name.replace("s_", "").replace("c.", "cc."))
        .toArray()
        .join(",");
    const query = selectDestination.serialize();
    if (query) {
        callSearchEngineHelper(query, searchField, (data: SearchFieldResponse) => {
            updateLinkedSelect(searchSelect, data);
            searchSelect.each((i, element) => {
                const oldValue = $(element).data("oldValue");
                const enabledOptions = $(element).find("option")
                    .filter((j, option) => $(option).attr("disabled") !== "disabled")
                    .filter((j, option) => $(option).val() === oldValue);
                if (enabledOptions.length) {
                    $(element).val(oldValue);
                    $(element).trigger("chosen:updated");
                }
                $(element).data("oldValue", "");
            });
        });
    }
}

function handleChangeDestination(evt: JQuery.Event<HTMLSelectElement>) {
    const selectDestination = $(evt.currentTarget);
    const form = $(evt.delegateTarget);
    const defaultSelect = form.find(".default-select");
    defaultSelect.each((i, element) => {
        $(element).data("oldValue", $(element).val());
    });
    defaultSelect.val("");
    updateSelectFromDestinationValue(form, selectDestination);
}

export function initDatepicker() {
    flatpickr(".datepicker-home", {
        altInput: true,
        altFormat: "d/m/Y",
        disableMobile: "false",
        minDate: "today",
        "locale": French
    });
}


